import {ILanguage, LoanType} from './interface';

const PATH = {
  DASHBOARD: '/',
  LOAN_DETAILS: '/loan-details',
  MY_LOANS: '/my-advance',
  LOAN_APPLICATION_PROCESS: '/loan-application-process',
  PAYMENTS_HISTORY: '/past-payment-history',
  RESUME_TEMPLATE_PATH: 'loan-application-process?loanApplicationId',
  PROFILE: '/my-profile',
  PAST_PAYMENT: '/repayment',
};

const VERSIONS = {
  V1: '/api/v1',
};

const AUTH_API_ENDPOINTS = {
  LOGIN: '/merchant/auth/login',
  LOGOUT: '/merchant/auth/logout',
  SELECT_BIN: '/merchant/auth/select-bin',
  GET_COUNTRIES: '/merchant/auth/countries-list',
  VALIDATE_MOBILE: '/merchant/auth/validate-mob-email',
  IMAGE: '/aws/auth/partner-logo',
};

const API_ENDPOINTS = {
  LOAN_APPLICATION_CONFIG: '/loan-application/config',
  INITIALIZE: '/loan-application/initialize',
  LOAN_INFO: '/loan-application/update-loan',
  ELIGIBLE_PHONE_NUMBERS: '/loan-application/eligible-phone-numbers',
  SEND_OTP: '/loan-application/send-otp',
  VERIFY_OTP: '/loan-application/verify-otp',
  TNC: '/loan-application/tnc',
  GET_LOAN_REVIEW_DETAILS: '/loan-application/loan-review-details',
  PI_DATA: '/loan-application/pi-data',
  UPDATE_PI_DATA: '/loan-application/update-pi',
  SUBMIT_LOAN_APPLICATION: '/loan-application/submit',
  LOAN_LIMIT: '/loan/limits',
  RESUME_LOAN_OFFERS: '/loan-application/resume-loan-offers',
  GET_LOAN_DETAILS: '/loan/details',
  GET_TRANSACTIONS: '/loan/transactions',
  LOAN_COUNT: '/loan/list-count',
  LOAN_LISTS: '/loan/lists',
  PROFILE_DETAILS: '/merchant/profile',
  GET_BANK_LIST: '/loan-application/bank-list',
  CREATE_MANDATE: '/loan-application/create-mandate',
  BANK_DETAIL_SAVE: '/loan-application/bankDetailSave',
  BUSINESS_INFO_SAVE: '/merchant/dtm/businessInfo-save',
  DIRECTOR_DETAILS_SAVE: '/merchant/dtm/directorDetails-save',
  INDUSTRY_LIST: '/merchant/dtm/industry-list',
  SUB_INDUSTRY_LIST: '/merchant/dtm/sub-industry-list',
  STATES_LIST: '/merchant/dtm/state-list',
  GET_INSTALL_URL: '/merchant/dtm/get-install-url',
  GET_VALIDATION: '/merchant/dtm/countryValidations',
  GET_PII_DETAILS: '/merchant/dtm/getPiiDetails',
  GET_PLATFORM_LIST: '/merchant/dtm/platformList',
  UPDATE_TO_PID: '/merchant/dtm/update-to-pid',
};

const CARD_MESSAGE = {
  EMPTY_lOAN_CARD_TEXT: 'You do not have any active advances',
};

const LOAN_APPLICATION_STEPS = [
  'Select  Advance Amount',
  'Select Your Tenure',
  'Review Your Advance Details',
  'Confirm Mobile Number',
  'Confirm Personal Details',
  'Terms & Conditions',
];

const LOAN_APPLICATION_STEPS_KEYS = {
  TENURE: 'step_2.tenure',
};
const INITIAL_LOAN_DATA = {
  id: '',
  loanStatus: '',
  bankName: '',
  dsr: '',
  van: '',
  loanType: '',
  currency: '',
  remainingRepaymentAmountTillDate: 0,
  repaidPercentageTillDate: 0,
  shortfallPercentageTillDate: 0,
  overduePercentageTillDate: 0,
  repaidAmountTillDate: 0,
  loanAmount: 0,
  tenureInWeeks: '',
  grossLoanAmount: 0,
  repaymentThrough: '',
  expected_first_repayment_date: '',
  actual_first_repayment_date: '',
  expected_last_repayment_date: '',
  estimated_last_repayment_date: '',
  actual_last_repayment_date: '',
  shortfallAmountTillDate: 0,
  overdueAmountTillDate: 0,
  expectedWeeklyRepaymentAmount: 0,
  vanRoutingCodeType: '',
  vanRoutingCodeValue: '',
  expectedRepaymentAmountTillDate: 0,
  fourWeeklyMinimumRepaymentAmount: 0,
};

const WHATS_APP_NUMBER = '+601117226265';

const LOGIN_PATNERS = [
  'senangPay',
  'curlec',
  'Curlec',
  'iPay88',
  'ipay88',
  'Pine Labs',
  'MobilityOne Sdn Bhd',
  'EasyEat',
  'LOLA',
  'Chip',
];

const LANGUAGES: ILanguage[] = [
  {value: 'en', text: 'English'},
  {value: 'ms', text: 'Bahasa Malaysia'},
  {value: 'id', text: 'Bahasa Indonesia'},
];

const domainToCountryCodeMap: {[key: string]: string} = {
  'seedflex.com': 'MY',
  'seedflex.id': 'ID',
  localhost: 'MY',
};

export {
  PATH,
  CARD_MESSAGE,
  LOAN_APPLICATION_STEPS,
  VERSIONS,
  API_ENDPOINTS,
  AUTH_API_ENDPOINTS,
  LOAN_APPLICATION_STEPS_KEYS,
  INITIAL_LOAN_DATA,
  WHATS_APP_NUMBER,
  LOGIN_PATNERS,
  LANGUAGES,
  domainToCountryCodeMap,
};
